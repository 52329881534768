import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { motion } from 'framer-motion';
import TrackVisibility from 'react-on-screen';
import { updateScrollbarScale } from '../components/store';

import SEO from '../components/seo';

const cardVariants = {
	hidden: {
		opacity: 0,
		scale: 0
	},
	visible: {
		opacity: 1,
		scale: 1
	}
}

const textVariants = {
	visible: {
		opacity: 1,
		x: 0
	},
	hidden: {
		opacity: 0,
		x: -300
	}
}

const ProjectsList = ({ data }) => (
	<>
		<motion.h2
			initial="hidden"
			animate="visible"
			variants={textVariants}
			transition={{
				delay: 0.35,
				type: 'spring'
			}}
			style={{ marginTop: 0 }}
		>
			<strong>CLIENTS</strong><br />Ils nous ont fait confiance
		</motion.h2>

		<div className="grid" style={{ justifyContent: 'center' }}>
			<motion.div
				className="card-container"
				initial="hidden"
				variants={cardVariants}
				animate="visible"
				transition={{
					delay: 0.35
				}}
			>
				<div className="card">
					<div className="card-image">
						<Img fluid={data.blackwayTmb.childImageSharp.fluid} />
					</div>
					<div className="card-caption">
						<p><strong>Black Way</strong></p>
						<p>Création du site</p>
					</div>
				</div>
			</motion.div>
			<motion.div
				className="card-container"
				initial="hidden"
				variants={cardVariants}
				animate="visible"
				transition={{
					delay: 0.45
				}}
			>
				<div className="card">
					<div className="card-image">
						<Img fluid={data.mmrideTmb.childImageSharp.fluid} />
					</div>
					<div className="card-caption">
						<p><strong>MM Ride</strong></p>
						<p>Création du site</p>
					</div>
				</div>
			</motion.div>
			<motion.div
				className="card-container"
				initial="hidden"
				variants={cardVariants}
				animate="visible"
				transition={{
					delay: 0.55
				}}
			>
				<div className="card">
					<div className="card-image">
						<Img fluid={data.crossfitTmb.childImageSharp.fluid} />
					</div>
					<div className="card-caption">
						<p><strong>CrossFit District Seujet</strong></p>
						<p>Charte graphique et site</p>
					</div>
				</div>
			</motion.div>
			<motion.div
				className="card-container"
				initial="hidden"
				variants={cardVariants}
				animate="visible"
				transition={{
					delay: 0.65
				}}
			>
				<div className="card">
					<div className="card-image">
						<Img fluid={data.pezzoliTmb.childImageSharp.fluid} />
					</div>
					<div className="card-caption">
						<p><strong>Atelier PE</strong></p>
						<p>Création du site</p>
					</div>
				</div>
			</motion.div>
			<motion.div
				className="card-container"
				initial="hidden"
				variants={cardVariants}
				animate="visible"
				transition={{
					delay: 0.75
				}}
			>
				<div className="card">
					<div className="card-image">
						<Img fluid={data.strobstudioTmb.childImageSharp.fluid} />
					</div>
					<div className="card-caption">
						<p><strong>Strob Studio</strong></p>
						<p>Charte graphique et site</p>
					</div>
				</div>
			</motion.div>
			<motion.div
				className="card-container"
				initial="hidden"
				variants={cardVariants}
				animate="visible"
				transition={{
					delay: 0.75
				}}
			>
				<div className="card">
					<div className="card-image">
						<Img fluid={data.obersonTmb.childImageSharp.fluid} />
					</div>
					<div className="card-caption">
						<p><strong>Boulangerie Oberson</strong></p>
						<p>Création du site</p>
					</div>
				</div>
			</motion.div>
			<motion.div
				className="card-container"
				initial="hidden"
				variants={cardVariants}
				animate="visible"
				transition={{
					delay: 0.75
				}}
			>
				<div className="card">
					<div className="card-image">
						<Img fluid={data.cvertTmb.childImageSharp.fluid} />
					</div>
					<div className="card-caption">
						<p><strong>C'Vert</strong></p>
						<p>Charte graphique et site</p>
					</div>
				</div>
			</motion.div>
			<motion.div
				className="card-container"
				initial="hidden"
				variants={cardVariants}
				animate="visible"
				transition={{
					delay: 0.75
				}}
			>
				<div className="card">
					<div className="card-image">
						<Img fluid={data.mvdbTmb.childImageSharp.fluid} />
					</div>
					<div className="card-caption">
						<p><strong>MVDB</strong></p>
						<p>Consulting SaaS</p>
					</div>
				</div>
			</motion.div>
			<motion.div
				className="card-container"
				initial="hidden"
				variants={cardVariants}
				animate="visible"
				transition={{
					delay: 0.75
				}}
			>
				<div className="card">
					<div className="card-image">
						<Img fluid={data.bulasccTmb.childImageSharp.fluid} />
					</div>
					<div className="card-caption">
						<p><strong>Bula SCC</strong></p>
						<p>Création du site</p>
					</div>
				</div>
			</motion.div>
		</div>
	</>
);

const ClientsList = ({ data, isVisible }) => (
	<>
		<motion.h2
			animate={isVisible ? 'visible' : 'hidden'}
			variants={textVariants}
			transition={{ type: 'spring' }}
		>
			<strong>CLIENTS</strong><br />Ils nous ont fait confiance
		</motion.h2>

		<div className="grid">
			<motion.div
				className="card-container"
				initial="hidden"
				variants={cardVariants}
				animate={isVisible ? 'visible' : 'hidden'}
				transition={{
					delay: 0
				}}
			>
				<div className="card">
					<div className="card-image">
						<Img fluid={data.obersonTmb.childImageSharp.fluid} />
					</div>
					<div className="card-caption">
						<p><strong>Boulangerie Oberson</strong></p>
					</div>
				</div>
			</motion.div>
			<motion.div
				className="card-container"
				initial="hidden"
				variants={cardVariants}
				animate={isVisible ? 'visible' : 'hidden'}
				transition={{
					delay: 0.1
				}}
			>
				<div className="card">
					<div className="card-image">
						<Img fluid={data.mvdbTmb.childImageSharp.fluid} />
					</div>
					<div className="card-caption">
						<p><strong>MVDB</strong></p>
					</div>
				</div>
			</motion.div>
			<motion.div
				className="card-container"
				initial="hidden"
				variants={cardVariants}
				animate={isVisible ? 'visible' : 'hidden'}
				transition={{
					delay: 0.2
				}}
			>
				<div className="card">
					<div className="card-image">
						<Img fluid={data.stpierregeneveTmb.childImageSharp.fluid} />
					</div>
					<div className="card-caption">
						<p><strong>Paroisse Saint-Pierre Genève</strong></p>
					</div>
				</div>
			</motion.div>
			<motion.div
				className="card-container"
				initial="hidden"
				variants={cardVariants}
				animate={isVisible ? 'visible' : 'hidden'}
				transition={{
					delay: 0.3
				}}
			>
				<div className="card">
					<div className="card-image">
						<Img fluid={data.bulasccTmb.childImageSharp.fluid} />
					</div>
					<div className="card-caption">
						<p><strong>Atelier PE</strong></p>
					</div>
				</div>
			</motion.div>
		</div>
	</>
);

const PartnersList = ({ data, isVisible }) => (
	<>
		<motion.h2
			animate={isVisible ? 'visible' : 'hidden'}
			variants={textVariants}
			transition={{ type: 'spring' }}
		>
			<strong>PARTENAIRES</strong><br />Nous travaillons souvent avec
		</motion.h2>

		<div className="grid grid-partners" style={{ justifyContent: 'center' }}>
			<motion.div
				className="card-container"
				style={{ flex: '0 0 33.333%' }}
				initial="hidden"
				variants={cardVariants}
				animate={isVisible ? 'visible' : 'hidden'}
				transition={{
					delay: 0
				}}
			>
				<div className="card">
					<div className="card-image">
						<Img fluid={data.etikpubTmb.childImageSharp.fluid} />
					</div>
					<div className="card-caption">
						<p><strong>Etik'Pub</strong></p>
						<p>Réalisations publicitaires</p>
					</div>
				</div>
			</motion.div>
			<motion.div
				className="card-container"
				style={{ flex: '0 0 33.333%' }}
				initial="hidden"
				variants={cardVariants}
				animate={isVisible ? 'visible' : 'hidden'}
				transition={{
					delay: 0.1
				}}
			>
				<div className="card">
					<div className="card-image">
							<Img fluid={data.creamorphicTmb.childImageSharp.fluid} />
						</div>
					<div className="card-caption">
						<p><strong>Creamorphic</strong></p>
						<p>Filmmaking & Photography</p>
					</div>
				</div>
			</motion.div>
		</div>
	</>
);

const Clients = ({ data, dispatch }) => {
	useEffect(() => {
		dispatch(updateScrollbarScale(100));

		let t = setTimeout(() => {
			const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight);
			const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
			dispatch(updateScrollbarScale(viewportHeight / documentHeight * 100));
		}, 400);

		return () => clearTimeout(t);
	}, [dispatch]);

	return (
		<section className="clients">
			<SEO
				title="Clients"
				description="Découvrez les différents projets d'applications webs sur lesquels nous avons travaillé."
			/>

			<motion.div
				className="bar-1"
				animate={{
					backgroundPositionY: 0
				}}
				transition={{
					delay: 0.35,
					type: 'spring'
				}}
			/>
			<motion.div
				className="bar-2"
				animate={{
					backgroundPositionY: 0
				}}
				transition={{
					delay: 0.35,
					type: 'spring'
				}}
			/>

			<div className="text-content">
				<ProjectsList data={data} />

				{/*<TrackVisibility data={data} once partialVisibility offset={-150}>
					<ClientsList />
				</TrackVisibility>*/}

				<TrackVisibility data={data} once partialVisibility offset={-150}>
					<PartnersList />
				</TrackVisibility>
			</div>
		</section>
	);
}

export default connect()(Clients);

export const query = graphql`
	query {
		blackwayTmb: file(relativePath: { eq: "blackway.png" }) {
			childImageSharp {
				fluid(maxWidth: 512, maxHeight: 512) {
					...GatsbyImageSharpFluid
				}
			}
		},
		mmrideTmb: file(relativePath: { eq: "mmride.png" }) {
			childImageSharp {
				fluid(maxWidth: 512, maxHeight: 512) {
					...GatsbyImageSharpFluid
				}
			}
		},
		crossfitTmb: file(relativePath: { eq: "crossfit.png" }) {
			childImageSharp {
				fluid(maxWidth: 512, maxHeight: 512) {
					...GatsbyImageSharpFluid
				}
			}
		},
		strobstudioTmb: file(relativePath: { eq: "strobstudio.png" }) {
			childImageSharp {
				fluid(maxWidth: 512, maxHeight: 512) {
					...GatsbyImageSharpFluid
				}
			}
		},
		bulasccTmb: file(relativePath: { eq: "bulascc.png" }) {
			childImageSharp {
				fluid(maxWidth: 512, maxHeight: 512) {
					...GatsbyImageSharpFluid
				}
			}
		},
		obersonTmb: file(relativePath: { eq: "oberson.png" }) {
			childImageSharp {
				fluid(maxWidth: 512, maxHeight: 512) {
					...GatsbyImageSharpFluid
				}
			}
		},
		pezzoliTmb: file(relativePath: { eq: "pezzoli.png" }) {
			childImageSharp {
				fluid(maxWidth: 512, maxHeight: 512) {
					...GatsbyImageSharpFluid
				}
			}
		},
		mvdbTmb: file(relativePath: { eq: "mvdb.png" }) {
			childImageSharp {
				fluid(maxWidth: 512, maxHeight: 512) {
					...GatsbyImageSharpFluid
				}
			}
		},
		cvertTmb: file(relativePath: { eq: "cvert.png" }) {
			childImageSharp {
				fluid(maxWidth: 512, maxHeight: 512) {
					...GatsbyImageSharpFluid
				}
			}
		},
		etikpubTmb: file(relativePath: { eq: "etikpub.png" }) {
			childImageSharp {
				fluid(maxWidth: 512, maxHeight: 256) {
					...GatsbyImageSharpFluid
				}
			}
		},
		creamorphicTmb: file(relativePath: { eq: "creamorphic.png" }) {
			childImageSharp {
				fluid(maxWidth: 512, maxHeight: 256) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
